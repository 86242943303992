<template>
    <div class="form-box">
        <div class="form-header">
            申请发票
        </div>
        <div class="form-main">
            <el-form :model="form" label-width="150px" ref="formRef" :rules="rules" scroll-to-error>
                <el-form-item label="发票类型：">
                    <el-select v-model="form.invoice_type" @change="change">
                        <el-option v-for="option in options" :key="option.value" :value="option.value"
                            :label="option.label" />
                    </el-select>
                </el-form-item>
                <el-form-item label="开票金额：" prop="invoice_money">
                    <el-input v-model="form.invoice_money"></el-input>
                </el-form-item>
                <el-form-item label="一般纳税人证明：" prop="taxpayer_image" v-if="form.invoice_type == 1">
                    <custom-upload ref="uploadRef" @success="uploadImg" :onlyUrl="true" :img="form.taxpayer_image" />
                    <el-input type="hidden" v-model="form.taxpayer_image" />
                </el-form-item>
                <el-form-item label="发票抬头：" prop="invoice_header">
                    <el-input v-model="form.invoice_header"></el-input>
                </el-form-item>
                <el-form-item label="纳税人识别号：" prop="taxpayer_identification_number">
                    <el-input v-model="form.taxpayer_identification_number"></el-input>
                </el-form-item>
                <el-form-item label="注册地址：" prop="register_address" :rules="[{ required: isHaveTo, message: '此项必填' }]">
                    <el-input v-model="form.register_address"></el-input>
                </el-form-item>
                <el-form-item label="登记电话：" prop="register_phone" :rules="[{ required: isHaveTo, message: '此项必填' }]">
                    <el-input v-model="form.register_phone"></el-input>
                </el-form-item>
                <el-form-item label="开户账户：" prop="basic_account" :rules="[{ required: isHaveTo, message: '此项必填' }]">
                    <el-input v-model="form.basic_account"></el-input>
                </el-form-item>
                <el-form-item label="开户行：" prop="bank_name" :rules="[{ required: isHaveTo, message: '此项必填' }]">
                    <el-input v-model="form.bank_name"></el-input>
                </el-form-item>
                <el-form-item label="收件人手机号：" prop="addressee_phone" v-if="form.invoice_type == 2"
                    :rules="[{ required: !isHaveTo, message: '此项必填' }]">
                    <el-input v-model="form.addressee_phone"></el-input>
                </el-form-item>
                <el-form-item label="收件人邮箱：" v-if="form.invoice_type == 2">
                    <el-input v-model="form.addressee_email"></el-input>
                </el-form-item>
                <el-form-item label="附加说明：">
                    <el-input type="textarea" :rows='3' v-model="form.additional_comments" />
                </el-form-item>
                <el-form-item label="发票备注：" v-if="form.invoice_type == 2">
                    <el-input type="textarea" :rows='3' v-model="form.remarks"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="form-footer">
            <el-button type="primary" @click="submit">确定</el-button>
        </div>
    </div>
</template>
<script setup>
import { ref, getCurrentInstance } from 'vue'
import CustomUpload from '@/components/CustomUpload'
import { ElMessage } from 'element-plus';

const { proxy } = getCurrentInstance() // 当前实例
const isHaveTo = ref(true)
const options = ref([
    { label: '专用增值税发票', value: 1 },
    { label: '普通增值税发票', value: 2 }
])

const form = ref({
    invoice_type: 1,
    invoice_money: '',
    taxpayer_image: '',
    invoice_header: '',
    taxpayer_identification_number: '',
    register_address: '',
    register_phone: '',
    basic_account: '',
    bank_name: '',
    addressee_phone: '',
    addressee_email: '',
    additional_comments: '',
    remarks: ''
})

const rules = ref({
    invoice_money: [
        { required: true, message: '此项必填', }
    ],
    taxpayer_image: [
        { required: true, message: '此项必填', }
    ],
    invoice_header: [
        { required: true, message: '此项必填', }
    ],
    taxpayer_identification_number: [
        { required: true, message: '此项必填', }
    ]
})

const change = (val) => {
    if (val == 1) {
        isHaveTo.value = true
    }
    if (val == 2) {
        isHaveTo.value = false
    }
}

const uploadImg = (imgUrl) => {
    form.value.taxpayer_image = imgUrl
}

const submit = () => {
    proxy.$refs.formRef.validate((validate) => {
        if (validate) {
            proxy.$api.invoice.apply(form.value).then(r => {
                if (r.status == 200) {
                    ElMessage({ message: '申请成功', type: 'success' })
                } else {
                    ElMessage({ message: '申请失败', type: 'error' })
                }
                proxy.$refs.formRef.resetFields()
                if (form.value.invoice_type == 1) {
                    proxy.$refs.uploadRef.setImgUrl('')
                }

                form.value = {
                    invoice_type: form.value.invoice_type,
                    additional_comments: ''
                }
            })
        }
    })
}

</script>

<style lang="less" scoped>
.form-box {
    width: 70%;
    margin: 0 auto;

    .form-header {
        font-size: 18px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgb(240, 240, 240);
    }

    .form-main {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        padding: 25px;
    }

    .form-footer {
        text-align: right;
        padding-right: 50px;
        padding-top: 30px;
        border-top: 1px solid rgb(240, 240, 240);
    }
}
</style>